import React, { useEffect } from "react";
import AuthDataService from "./common/services/auth.service";
import ConfigEnviroment from "./config/ConfigEnviroment";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { Container } from "react-bootstrap";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserProfile } from "./components/UserProfile";
import { useRootStore } from "./store/rootStore";

export default function Root(props) {
  const queryClient = new QueryClient();

  const { viewTypes, showView } = props;
  const {
    viewTypes: vTypes,
    setViewTypes,
    setShowView,
  } = useRootStore((state) => state);

  useEffect(() => {
    setViewTypes(viewTypes);
    setShowView(showView);
  }, [viewTypes]);

  useEffect(() => {
    if (props.name === "@froneus/evi-user-profile-site") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <Container className="evi-audit audit">
      <QueryClientProvider client={queryClient}>
        {vTypes && setShowView && <UserProfile />}
      </QueryClientProvider>
    </Container>
  );
}
