import React from "react";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { DataView } from "primereact/dataview";
import { Fieldset } from "primereact/fieldset";
import { AreaChart } from "./AreaChart";

export const Consumos = () => {
  const stats = [
    { title: "Consumo Total", value: "570 kWh", icon: "pi pi-bolt" },
    { title: "Costo Estimado", value: "$75.80", icon: "pi pi-wallet" },
    { title: "Ahorro Anual", value: "$900", icon: "pi pi-sun" },
  ];

  const tableData = [
    { id: 1, item: "Electricidad", consumo: "500 kWh", costo: "$100" },
    { id: 2, item: "Agua", consumo: "20 m³", costo: "$30" },
    { id: 3, item: "Gas", consumo: "50 m³", costo: "$40" },
    { id: 4, item: "Electricidad", consumo: "510 kWh", costo: "$102" },
    { id: 5, item: "Agua", consumo: "22 m³", costo: "$32" },
    { id: 6, item: "Gas", consumo: "55 m³", costo: "$45" },
    { id: 7, item: "Electricidad", consumo: "520 kWh", costo: "$104" },
    { id: 8, item: "Agua", consumo: "25 m³", costo: "$35" },
    { id: 9, item: "Gas", consumo: "60 m³", costo: "$50" },
  ];

  const dataViewData = [
    { name: "Panel Solar", ahorro: "30%", status: "Activo" },
    { name: "Producto Eficiente", ahorro: "15%", status: "Activo" },
    { name: "Medidor Inteligente", ahorro: "10%", status: "Pendiente" },
  ];

  const dataViewTemplate = (item: any) => (
    <div className="data-item">
      <h4>{item.name}</h4>
      <p>
        Ahorro: <strong>{item.ahorro}</strong>
      </p>
      <p>
        Estatus: <strong>{item.status}</strong>
      </p>
    </div>
  );

  const timelineData = [
    {
      status: "Inicio",
      date: "01/01/2024",
      icon: "pi pi-calendar",
      color: "#9C27B0",
      description: "Monitoreo de consumo iniciado.",
      additionalInfo: "Se activó el sistema de monitoreo.",
    },
    {
      status: "Ajuste",
      date: "15/01/2024",
      icon: "pi pi-cog",
      color: "#673AB7",
      description: "Revisión de consumos eléctricos.",
      additionalInfo:
        "Revisamos los consumos mensuales y ajustamos la configuración.",
    },
    {
      status: "Revisión",
      date: "01/02/2024",
      icon: "pi pi-search",
      color: "#FF9800",
      description: "Actualización de datos de consumo.",
      additionalInfo:
        "Actualizamos los datos basados en los consumos más recientes.",
    },
    {
      status: "Cierre",
      date: "28/02/2024",
      icon: "pi pi-check",
      color: "#607D8B",
      description: "Reporte finalizado.",
      additionalInfo: "El reporte de consumo fue generado y distribuido.",
    },
  ];

  return (
    <div style={{ padding: "2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        {/* Columna 1 */}
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
            }}
          >
            {stats.map((stat, index) => (
              <Card
                key={index}
                title={stat.title}
                style={{
                  flex: 1,
                  textAlign: "center",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <i
                  className={stat.icon}
                  style={{
                    fontSize: "2rem",
                    color: "#007ad9",
                    marginBottom: "0.5rem",
                  }}
                />
                <p
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 700,
                    color: "gray",
                  }}
                >
                  {stat.value}
                </p>
              </Card>
            ))}
          </div>

          {/* Timeline */}
          <Panel header="Progreso de Consumo" className="time-line">
            <Timeline
              value={timelineData}
              align="left"
              marker={(item) => (
                <i
                  className={item.icon}
                  style={{
                    color: item.color,
                    fontSize: "1.5rem",
                  }}
                />
              )}
              content={(item) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flex: 1 }}>
                    <span className="p-text-bold">{item.status}</span>
                    <p>{item.description}</p>
                    <small>{item.date}</small>
                  </div>
                  <div style={{ flex: 1, color: "gray" }}>
                    <small>{item.additionalInfo}</small>
                  </div>
                </div>
              )}
            />
          </Panel>

          {/* Tabla de datos */}
          <Panel header="Consumo Detallado" style={{ marginTop: "1rem" }}>
            <DataTable value={tableData} responsiveLayout="scroll">
              <Column field="item" header="Recurso" />
              <Column field="consumo" header="Consumo" />
              <Column field="costo" header="Costo" />
            </DataTable>
          </Panel>

          {/* Fieldset con información adicional */}
          <Fieldset
            legend="Información Adicional"
            style={{ marginTop: "1rem" }}
          >
            <p>
              Este dashboard ofrece una vista detallada del consumo de recursos
              (electricidad, agua, gas) y las acciones realizadas para optimizar
              el uso de los mismos. Mantén el monitoreo de tus consumos para
              reducir costos y mejorar la eficiencia energética.
            </p>
            <p>
              Además, se incluyen datos clave sobre los ahorros generados por
              recursos activos, como paneles solares y medidores inteligentes.
            </p>
          </Fieldset>
        </div>

        {/* Columna 2 */}
        <div style={{ flex: "0.5" }}>
          <AreaChart />

          {/* DataView */}
          <Panel header="Proyectos de Ahorro" className="data-view">
            <DataView
              value={dataViewData}
              layout="grid"
              itemTemplate={dataViewTemplate}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            />
          </Panel>
        </div>
      </div>
    </div>
  );
};
