import React from "react";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import { Fieldset } from "primereact/fieldset";

export const Audit = () => {
  const stats = [
    { title: "Consumo Total", value: "1,200 kWh", icon: "pi pi-bolt" },
    { title: "Costo Estimado", value: "$180.50", icon: "pi pi-wallet" },
    { title: "Ahorro Potencial", value: "$50.00", icon: "pi pi-save" },
  ];

  const tableData = [
    { id: 1, item: "Electricidad", consumo: "600 kWh", costo: "$90" },
    { id: 2, item: "Agua", consumo: "25 m³", costo: "$35" },
    { id: 3, item: "Gas", consumo: "60 m³", costo: "$55" },
    { id: 4, item: "Electricidad", consumo: "620 kWh", costo: "$92" },
    { id: 5, item: "Agua", consumo: "30 m³", costo: "$40" },
    { id: 6, item: "Gas", consumo: "65 m³", costo: "$58" },
    { id: 7, item: "Electricidad", consumo: "640 kWh", costo: "$95" },
    { id: 8, item: "Agua", consumo: "35 m³", costo: "$45" },
  ];

  const barChartData = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo"],
    datasets: [
      {
        label: "Consumo Mensual (kWh)",
        data: [600, 620, 610, 630, 650],
        backgroundColor: "#42A5F5",
      },
    ],
  };

  const lineChartData = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo"],
    datasets: [
      {
        label: "Ahorro Estimado ($)",
        data: [20, 25, 30, 40, 50],
        fill: false,
        borderColor: "#FF9800",
        tension: 0.1,
      },
    ],
  };

  return (
    <div style={{ padding: "2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex", gap: "1rem", marginBottom: "2rem" }}>
            {stats.map((stat, index) => (
              <Card
                key={index}
                title={stat.title}
                style={{
                  flex: 1,
                  textAlign: "center",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <i
                  className={stat.icon}
                  style={{
                    fontSize: "2rem",
                    color: "#007ad9",
                    marginBottom: "0.5rem",
                  }}
                />
                <p
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 700,
                    color: "gray",
                  }}
                >
                  {stat.value}
                </p>
              </Card>
            ))}
          </div>

          <Panel header="Consumo Detallado">
            <DataTable value={tableData} responsiveLayout="scroll">
              <Column field="item" header="Recurso" />
              <Column field="consumo" header="Consumo" />
              <Column field="costo" header="Costo" />
            </DataTable>
          </Panel>
        </div>

        <div style={{ flex: 0.7 }}>
          <Panel header="Análisis de Consumo" style={{ marginBottom: "2rem" }}>
            <Chart type="bar" data={barChartData} />
          </Panel>

          <Panel header="Estimación de Ahorro" style={{ marginTop: "2rem" }}>
            <Chart type="line" data={lineChartData} />
          </Panel>
        </div>
      </div>

      <Fieldset legend="Información Adicional" style={{ marginTop: "2rem" }}>
        <p>
          Este dashboard de auditoría muestra los consumos de diferentes
          recursos (electricidad, agua, gas) a lo largo de varios meses. Los
          gráficos ofrecen una visión clara del comportamiento de los consumos y
          ahorros estimados.
        </p>
        <p>
          Las cartas informativas resumen el estado actual del consumo y los
          ahorros potenciales, ayudando a los usuarios a comprender rápidamente
          los costos y las oportunidades de optimización.
        </p>
      </Fieldset>
    </div>
  );
};
