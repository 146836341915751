import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Profile } from "./Profile";
import { Consumos } from "./Consumos";
import { Audit } from "./Audit";

export const UserProfile = () => {
  return (
    <div
      className="user-profile card"
      style={{ minHeight: "calc(100vh - 80px)", marginBottom: "1rem" }}
    >
      <TabView>
        <TabPanel header="Perfil">
          <Profile />
        </TabPanel>
        <TabPanel header="Consumos Campañas">
          <Consumos />
        </TabPanel>
        <TabPanel header="Consumos Speach Analytics">
          <Audit />
        </TabPanel>
      </TabView>
    </div>
  );
};
