import React from "react";
import { Avatar } from "primereact/avatar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { OrganizationChart } from "primereact/organizationchart";

export const Profile: React.FC = () => {
  const orgData = [
    {
      label: "Juan Pérez",
      data: "CEO",
      children: [
        {
          label: "María López",
          data: "Gerente de Marketing",
          children: [
            { label: "Carlos García", data: "Especialista SEO" },
            { label: "Lucía Martínez", data: "Community Manager" },
          ],
        },
        {
          label: "Pedro González",
          data: "Gerente de Tecnología",
          children: [
            { label: "Sofía Ruiz", data: "Desarrolladora Backend" },
            { label: "David Sánchez", data: "Desarrollador Frontend" },
          ],
        },
      ],
    },
  ];

  return (
    <div style={{ padding: "2rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginBottom: "1.5rem",
        }}
      >
        <div style={{ marginRight: "1.5rem" }}>
          <Avatar
            icon="pi pi-user"
            size="xlarge"
            shape="circle"
            style={{ backgroundColor: "#f0f0f0", color: "#555" }}
          />
        </div>
        <div>
          <h3 style={{ margin: "0 0 0.5rem 0" }}>Juan Pérez</h3>
          <p style={{ margin: 0, color: "#555" }}>juan.perez@example.com</p>
          <Button
            label="Editar Perfil"
            icon="pi pi-user-edit"
            className="p-button-primary"
            style={{ marginTop: "0.5rem" }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <div style={{ flex: 1 }}>
          <Panel header="Información Personal">
            <p>
              <strong>Teléfono:</strong> +34 123 456 789
            </p>
            <p>
              <strong>Dirección:</strong> Calle Falsa 123, Rosario, Argentina
            </p>
            <p>
              <strong>Miembro desde:</strong> Enero 2020
            </p>
          </Panel>
        </div>

        <div style={{ flex: 1 }}>
          <Panel header="Detalles Adicionales">
            <p>
              <strong>Fecha de Nacimiento:</strong> 15 de Junio de 1990
            </p>
            <p>
              <strong>Profesión:</strong> Ingeniero de Software
            </p>
            <p>
              <strong>Hobbies:</strong> Programación, Fotografía, Viajes
            </p>
          </Panel>
        </div>
      </div>

      {/* Organigrama */}
      <div style={{ marginTop: "2rem" }}>
        <Panel header="Organización" style={{ width: "100%" }}>
          <OrganizationChart value={orgData} />
        </Panel>
      </div>
    </div>
  );
};
