import React, { useState } from "react";
import { Chart } from "primereact/chart";
import { Panel } from "primereact/panel";
import { Card } from "primereact/card";

export const AreaChart: React.FC = () => {
  const lineChartOptions = [
    {
      title: "Consumo Energía",
      data: {
        labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo"],
        datasets: [
          {
            label: "Energía (kWh)",
            data: [500, 520, 530, 550, 560],
            fill: true,
            backgroundColor: "rgba(66, 165, 245, 0.2)",
            borderColor: "#42A5F5",
          },
        ],
      },
    },
    {
      title: "Consumo Agua",
      data: {
        labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo"],
        datasets: [
          {
            label: "Agua (m³)",
            data: [20, 22, 25, 27, 30],
            fill: true,
            backgroundColor: "rgba(102, 187, 106, 0.2)",
            borderColor: "#66BB6A",
          },
        ],
      },
    },
    {
      title: "Consumo Gas",
      data: {
        labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo"],
        datasets: [
          {
            label: "Gas (m³)",
            data: [50, 55, 60, 65, 70],
            fill: true,
            backgroundColor: "rgba(255, 167, 38, 0.2)",
            borderColor: "#FFA726",
          },
        ],
      },
    },
  ];

  const [activeChart, setActiveChart] = useState(lineChartOptions[0]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      {/* Previews de gráficos */}
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flex: 0.5, // Previews más pequeños
          overflow: "hidden",
        }}
      >
        {lineChartOptions.map((chart, index) => (
          <Card
            key={index}
            style={{
              flex: 1,
              cursor: "pointer",
              maxHeight: "200px", // Limitar la altura de los previews
              overflow: "hidden", // Asegura que el gráfico no se desborde
            }}
            onClick={() => setActiveChart(chart)}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <Chart
                type="line"
                data={chart.data}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </Card>
        ))}
      </div>

      {/* Gráfico principal */}
      <Panel header={activeChart.title} style={{ flex: 2 }}>
        <div style={{ width: "100%", height: "100%" }}>
          <Chart
            type="line"
            data={activeChart.data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </Panel>

      {/* Gráfico de dona */}
      <Panel header="Distribución de Consumo" style={{ flex: 1 }}>
        <div style={{ width: "100%", height: "100%" }}>
          <Chart
            type="doughnut"
            data={{
              labels: ["Electricidad", "Agua", "Gas"],
              datasets: [
                {
                  data: [500, 20, 50],
                  backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
                  hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </Panel>
    </div>
  );
};
